import React from "react";
import { Link } from "gatsby";
import {
  CollapsibleTrees,
  CompletionPercentage,
  ExercisePage,
  Focus,
  GoToTree,
  InAppWorkout,
  SaveSkillCount,
  SkillFilter,
  TooltipButton,
  Tooltips,
  Tour,
  VideoFlow,
  WorkoutBuilder
} from "./gifs";
import { Homepage, RadarChart } from "./images";

interface Release {
  version: string;
  title: string;
  date: string;
  description: React.ReactElement;
  gif?: string;
  img?: JSX.Element;
}

const releases: Release[] = [
  {
    version: "v2.1.0",
    title: "All Workouts Can Be Now Performed In-App",
    date: new Date(2020, 6, 7).toDateString(),
    description: (
      <>
        <p>
          You can now perform a Recommended Routine, First Pull Ups (+ Beyond),
          and Improve Your Isometric Holds workouts can now be performed in-app.
        </p>
        <p>
          Get started by heading to the{" "}
          <Link to="/workouts/workouts-list">workouts list</Link>.
        </p>
      </>
    )
  },
  {
    version: "v2.0.0",
    title: "Cali Skills Premium",
    date: new Date(2020, 6, 1).toDateString(),
    img: <RadarChart alt="Screenshot of the Cali Skills homepage" />,
    description: (
      <>
        <p>We're pleased to announce Cali Skills Premium</p>
        <p>
          A brand new pricing tier that comes with a whole host of incredible
          features.{" "}
        </p>
        <p>All of our premium users get:</p>
        <ul>
          <li>A more flexible workout builder</li>
          <li>Gorgeous progress dashboards</li>
          <li>Additional progressions and skill trees</li>
        </ul>
        <p>
          We've also made some accessibility improvements to make Cali Skills a
          little easier to use.
        </p>
      </>
    )
  },
  {
    version: "v1.16.3",
    title: "14 Brand New Exercise Icons",
    date: new Date(2020, 4, 25).toDateString(),
    description: (
      <p>
        To make our In App Workout more accessible for everyone using
        Cali Skills, we've been working hard to add new icons. These
        icons display during your workout for certain exercises. These exercises
        include Planche, Front Lever, Back Leverl, and more.
      </p>
    )
  },
  {
    version: "v1.16.0",
    title: "In App Workout",
    gif: InAppWorkout,
    date: new Date(2020, 4, 6).toDateString(),
    description: (
      <p>
        The whole Calisthenics experience, in one app. You can now perform your
        custom workout within Cali Skills. Just build your workout in
        the <Link to="/workouts">Workout Builder</Link> and click the 'Perform
        the Workout' button. We'll then be guided along your workout, with
        exercises that we've chosen based on your current progress.
      </p>
    )
  },
  {
    version: "v1.15.0",
    title: "Workout Builder",
    gif: WorkoutBuilder,
    date: new Date(2020, 3, 7).toDateString(),
    description: (
      <p>
        If none of our pre-made workout routines fit your goals, then use our{" "}
        <Link to="/workouts">Workout Builder</Link> to build your own. Select
        the progressions you'd like to work on, and we'll create a bespoke
        workout based on your Skill Tree progress.
      </p>
    )
  },
  {
    version: "v1.13.2",
    title: "Profile Page",
    date: new Date(2020, 2, 27).toDateString(),
    description: (
      <p>
        Update your details in the <Link to="/profile">profile page</Link>. With
        this info, Cali Skills can begin to create a more personalised
        experience for you.
      </p>
    )
  },
  {
    version: "v1.13.1",
    title: "Calisthenics Equipment Guide",
    date: new Date(2020, 2, 18).toDateString(),
    description: (
      <p>
        Learn calisthenics without the gym membership. You can set up your very
        own home gym with our <Link to="/equipment">equipment guide</Link>.
      </p>
    )
  },
  {
    version: "v1.13.0",
    title: "Focus on a Skill",
    date: new Date(2020, 2, 14).toDateString(),
    gif: Focus,
    description: (
      <p>
        Never lose sight of your goals. Focusing on a skill will keep it stuck
        to the top of your Skill Tree.
      </p>
    )
  },
  {
    version: "v1.12.1",
    title: "Skill Tree Completion Amount",
    date: new Date(2020, 2, 7).toDateString(),
    gif: CompletionPercentage,
    description: (
      <p>
        See how far you've progressed at a glance. Each skill tree now has it's
        completion percentage visible for you to see.
      </p>
    )
  },
  {
    version: "v1.12.0",
    title: "Streamlined Skill Trees",
    date: new Date(2020, 2, 1).toDateString(),
    description: (
      <p>
        Your skill trees are now broken up by milestone exercises. We want to
        make calisthenics as simple as possible, and now it's even easier
        finding the skills that matter to you. Now you can better see what
        Cali Skills has to offer.
      </p>
    )
  },
  {
    version: "v1.11.6",
    title: "Jump to Skill Tree",
    date: new Date(2020, 1, 22).toDateString(),
    gif: GoToTree,
    description: (
      <p>
        Can't quite find that skill you're looking for? Now you can jump
        straight to it from the Skill filter. This is part of our ongoing work
        to make Cali Skills even simpler.
      </p>
    )
  },
  {
    version: "v1.11.5",
    title: "Improve your Isometric hold times",
    date: new Date(2020, 1, 16).toDateString(),
    description: (
      <p>
        Want to improve your L-Sit hold time? Generate a workout with our brand
        new workout format{" "}
        <Link to="/workouts/improve-your-isometric-holds/">
          Improve Your Isometric Holds
        </Link>
        .
      </p>
    )
  },
  {
    version: "v1.11.4",
    title: "Better saving visibility with the save button",
    date: new Date(2020, 1, 12).toDateString(),
    description: (
      <p>
        We've revamped the exercise card in your Skill Tree to make saving feel
        more intuitive.
      </p>
    ),
    gif: TooltipButton
  },
  {
    version: "v1.11.0",
    title: "Our brand new homepage",
    date: new Date(2020, 1, 10).toDateString(),
    description: (
      <p>
        You can learn all about the amazing feature that Cali Skills has
        to offer on our elegant new homepage.
      </p>
    ),
    img: <Homepage alt="Screenshot of the Cali Skills homepage" />
  },
  {
    version: "v1.10.0",
    title: "The Cali Skills tour",
    date: new Date(2020, 0, 25).toDateString(),
    description: (
      <>
        <p>
          Take the Cali Skills <Link to="/tour/">tour</Link> and
          discover how our tools can help you reach your fitness goals.
        </p>
        <p>
          You'll learn about the Skill Tree, Workout Generator, exercise and
          workout library and more.
        </p>
      </>
    ),
    gif: Tour
  },
  {
    version: "v1.9.8",
    title: "First Pull Ups and Beyond",
    date: new Date(2019, 11, 28).toDateString(),
    description: (
      <p>
        Achieving a Pull Up is one of the most common fitness goals. So
        Cali Skills has created a brand new workout format to help you
        reach your Pull Up goals. It's called{" "}
        <Link to="/workouts/first-pull-ups-and-beyond">
          First Pull Ups and Beyond
        </Link>{" "}
        and it's designed to use a variety of workout techniques to help you get
        your first <Link to="/exercises/pull-up">Pull Up</Link>.
      </p>
    )
  },
  {
    version: "v1.9.6",
    title: "Improved Workout Personalisation",
    date: new Date(2019, 11, 23).toDateString(),
    description: (
      <>
        <p>
          Reach your calisthenics goals more quickly and more easily with our
          improved{" "}
          <Link to="/workouts/recommended-routine">Recommended Routine</Link>{" "}
          workouts.
        </p>
        <p>
          Our Workout Generator now takes your progress into consideration. All
          you need to do is keep your Skill Tree update to date with your
          current progress and we do the rest.
        </p>
      </>
    )
  },
  {
    version: "v1.9.5",
    title: "Skill Tree Tooltips",
    date: new Date(2019, 11, 22).toDateString(),
    description: (
      <>
        <p>
          Find Calisthenics complicated? We're making it simpler for you. Our
          new skill tree tooltips give you a glimpse into each movement group,
          including the muscles worked and the milestone skills.
        </p>
        <p>Special thanks to volunteer Suhag for adding tooltips.</p>
      </>
    ),
    gif: Tooltips
  },
  {
    version: "v1.9.3",
    title: "Updates to Milestone Skills + Video Player",
    date: new Date(2019, 11, 16).toDateString(),
    description: (
      <>
        <p>
          To help track your milestone skills more easily the default icons have
          been removed and replaced with gold text.
        </p>
        <p>
          The tutorial video no longer opens in fullscreen by default, to make
          for a more a seamless Cali Skills experience.
        </p>
      </>
    ),
    gif: VideoFlow
  },
  {
    version: "v1.9.0",
    title: "Progress Tracking",
    date: new Date(2019, 11, 14).toDateString(),
    description: (
      <p>
        You can now measure your calisthenics progress even more precisely. You
        can track your max rep/hold count for each exercise to help keep you
        focused on your calisthenics goals. In the future, your more
        finely-grained progress can be used with the Workout Generator to create
        even more personalised workouts.
      </p>
    ),
    gif: SaveSkillCount
  },
  {
    version: "v1.8.0",
    title: "Exercise Page",
    date: new Date(2019, 11, 10).toDateString(),
    description: (
      <p>
        The <Link to="/exercises/pull-up">Pull Up</Link> page is the first of a
        growing list of pages dedicated for a specific exercise. This is the
        beginning of Cali Skills' bodyweight exercise library, which
        lives <Link to="/exercises/">here</Link>. You can find a description,
        exercise notes, difficulty levels and more for each exercise. You can
        also select your preferred gender for the instructional video.
      </p>
    ),
    gif: ExercisePage
  },
  {
    version: "v1.7.0",
    title: "Skill Filter",
    date: new Date(2019, 11, 2).toDateString(),
    description: (
      <p>
        With over 120 skills to complete, it's tricky focusing on a single
        skill. With the new Skill Filter, you can easily hone-in on the skills
        that matter most to you.
      </p>
    ),
    gif: SkillFilter
  },
  {
    version: "v1.6.2",
    title: "Collapsible Trees",
    date: new Date(2019, 10, 10).toDateString(),
    description: (
      <p>
        Is today leg day? Cut straight to the chase and collapse the skill trees
        to find the skills most important to you.
      </p>
    ),
    gif: CollapsibleTrees
  },
  {
    version: "v1.6.0",
    title: "Workout Generator",
    date: new Date(2019, 10, 6).toDateString(),
    description: (
      <p>
        The brand new Workout Generator helps bridge the gaps between each
        skill, so you can reach your goals quicker. Why not craate your very on
        workout using the tried-and-trusted{" "}
        <Link to="/workouts/recommended-routine/">Recommended Routine</Link>?
      </p>
    )
  },
  {
    version: "v1.5.0",
    title: "Sign up to Cali Skills",
    date: new Date(2019, 9, 29).toDateString(),
    description: (
      <p>
        Track your progress across all of your devices by signing up to
        Cali Skills. <Link to="/login/">Signing up</Link> to
        Cali Skills lets you be the first to try out the newest
        features, like the upcoming Workout Generator.
      </p>
    )
  }
];

export default releases;
