import React from "react";
import TextPageLayout from "../components/layouts/TextPageLayout";
import SEO from "../components/SEO";
import releaseNotesData from "../data/releases/index";

function ReleaseNotes() {
  return (
    <TextPageLayout title="Release Notes">
      <SEO
        path="/release-notes/"
        description="Keep up to date with the latest Cali Skills features and releases."
        title="Cali Skills' Release Notes"
      />
      {releaseNotesData.map(release => {
        return (
          <div key={release.date} style={{ marginBottom: "64px" }}>
            <h2>
              {release.version}: {release.title}
            </h2>
            <div style={{ display: "flex" }}>
              <h4>{release.date}</h4>
            </div>
            {release.description}
            {release.img && release.img}
            {release.gif && (
              <div
                style={{
                  border: "1px solid white",
                  borderRadius: "2px",
                  margin: "8px"
                }}
              >
                <img
                  src={release.gif}
                  alt={`demonstration for ${release.title.toLowerCase()}`}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          </div>
        );
      })}
    </TextPageLayout>
  );
}

export default ReleaseNotes;
